$(document).ready(function(){

    // navi burger mobile
    $('.burger-btn').click(function() {
        $('.hamburger-menu').toggleClass("is-active");
        $('.component_navigation_mobile').toggleClass("is-active");
    });

    function scrollToAnchor(aid){
        var aTag = $("a[name='"+ aid +"']");
        $('html,body').animate({scrollTop: aTag.offset().top},'fast');
    }

    $( ".card-header" ).click(function() {
        var id = $(this).attr('data-accordion');
        scrollToAnchor(id);
    });

    $( "aside .section-wrapper" ).addClass('active');


    $( ".arrow-down" ).click(function() {
        if ($(this).hasClass('open')) {
            console.log("remove");
            $(this).parent().parent().removeClass('open');
            $(this).parent().parent().prev('li').removeClass('hideborder');
            $(this).removeClass('open');
            var id = $(this).data("id");
            $( ".navid" + id ).addClass('d-none');
        } else {
            console.log("add");
            $(this).parent().parent().addClass('open');
            $(this).parent().parent().prev('li').addClass('hideborder');
            $(this).addClass('open');
            var id = $(this).data("id");
            $( ".navid" + id ).removeClass('d-none');
        }
    });


});